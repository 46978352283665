.video-tasks-container{
    padding-bottom: 86px;
    width: 100%;
    height: 100%;
}
.video-task-page{
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    position: relative;
}
.video-task-page button{
    background: none;
    border: none;
}
.iframe-container{
    flex: 1;
    position: relative;
}
.iframe-container-overlay{
    position: absolute;
    inset: 0;
    pointer-events: none;
    z-index: 10;
}
.iframe-container::after,.iframe-container::before{
    content: '';
    position: absolute;
    left: 0;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    width: 30%;
}
.iframe-container::after{
    left: auto;
    right: 0;
}
.iframe-container iframe,.iframe-container video{
    width: 100% !important;
    height: 100% !important;
}
.video-task-info{
    height: 36px;
    position: relative;
}
.video-task-info .video-task-reward{
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 5px;
}
.video-task-info-body{
    background-image: url(../../img/bg-light-glow.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}
.video-task-info-buttons{
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content:center;
    padding: 0px 16px;
}
.video-task-info-hint{
    position: absolute;
    top: 20px;
    right: 10px;
}
.video-task-info-categories{
    width: 100%;
    overflow:hidden;
    padding: 15px 25px;
    
}
.video-task-info-categories ul{
    display: flex;
    align-items: stretch;
    gap: 4px;
    width: fit-content;
    min-width: 100%;
}
.video-task-info-categories ul li{
    list-style: none;
    padding: 7.5px 10px;
    font-size: 12px;
    width: max-content;
}
.video-task-info-categories ul li:first-child{
    padding-left: 0;
}
.video-task-info-reward{
    width: 100%;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.video-task-info-button{
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-task-reward-coins{
    display: flex;
    align-items: center;
    gap: 10px;
}
.video-task-reward-coins *{
    font-size: 16px;
}
.video-task-reward-coins-count{
    display: flex;
    align-items: center;
    gap: 4px;
}
.video-task-reward-coins-count img{
    width: 20px;
    height: 20px;
}
.video-task-question{
    position: absolute;
    bottom:88px;
    width: 100%;
    left: 0;
    height:inherit;
}
.video-task-question-body{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 10px 15px 20px;
    background-color: rgba(255, 255, 255, 0.64);
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
.video-task-question-body hr{
    background-color: rgba(255, 255, 255, 0.64);
    margin: 0 auto 10px;
    height: 5px;
    border-radius: 100px;
    display: block;
    border: none;
    width: 33%;
}
.video-task-question-body p{
    color: black;
    font-size: 20px;
    text-align: center;
}
.video-task-question-buttons{
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 40px;
    gap: 8px;
    width: 100%;
}

.video-task-question-buttons button{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 17px;
    color: white;
    background: radial-gradient(50% 325% at 50% 45.3%, #E02864 0%, #251B8B 76%);
}
.video-task-question-buttons.disabled button{
    background: rgb(129, 129, 129);
    /* border: 1px solid white; */
}
.video-task-question-buttons span.bigger{
    font-weight: 700;
}


.video-tark-reward-screen{
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.video-tark-reward-screen h2{
    font-size: 66px;
}

.video-task-info.pending{
    background: linear-gradient(270deg, #251b8b 0%, #e02864 50%, #251b8b 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.video-task-info.pending .tasks-timer{
    width: fit-content;
}
.close-video-task{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
}
.final-reward-screen{
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    position: absolute;
    inset: 0;
}