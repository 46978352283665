.screen-task-inner{
    padding: 28px 16px;
    position: fixed;
    top: 0;
    left: 0;
    width: inherit;
    height: calc(100vh - 87px) !important;
    background: url("./img/bg-light-glow.png") no-repeat center/cover;
    overflow-y:auto;
}
.close{
    position: absolute;
    top: 24px;
    right: 16px;
}

.task-inner-img{
    text-align: center;
    margin-bottom: 25px;
}
.task-inner-title{
    text-align: center;
    margin-bottom: 25px;
}
.task-inner-text {
    margin:0 auto;
    max-width: 333px;
    margin-bottom: 25px;

    font-family: 'InterRegular',sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}
.task-inner-text ul,.task-inner-text ol{
    display: block;
    list-style-type: decimal;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
    unicode-bidi: isolate;
}
.task-inner-money{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2px;
    margin-bottom: 25px;
}
.task-inner-money img{
    width: 80px;
    height: 80px;
}
.task-inner-money-count{
    font-family: 'InterBold',sans-serif;
    font-size: 34px;
    color: #FFFFFF;
}
.task-inner-button-go{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    width: 100%;
    height: 60px;

    background: #000000;
    border: 1.5px solid #3A3A3A;
    backdrop-filter: blur(40px);
    border-radius: 50px;

    font-family: 'SF Pro Text Medium',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 14px;
}
.task-inner-button-check{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    column-gap: 4px;
    width: 100%;
    height: 60px;
    background: radial-gradient(50% 689.8% at 50% 45.3%, #000000 0%, #5F2DCA 100%);
    opacity: 0.5;
    backdrop-filter: blur(40px);
    border-radius: 50px;
    margin-bottom: 16px;

    font-family: 'SF Pro Text Medium',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}
.check-active{
    opacity: 1;
    backdrop-filter: none
}











