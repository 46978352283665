.screen {
    padding: 24px 16px 24px 16px;
    height: 100%;
    position: relative;
    
    margin: 0 auto;
}
.close{
    position: absolute;
    top: 24px;
    right: 16px;
}

.done-coins-title{
    margin-top: 76px;
    margin-bottom: 100px;
    text-align: center;

    font-family: 'InterBold',sans-serif;
    font-size: 34px;
    color: #FFFFFF;
}
.money{
    margin: 0 auto;
    box-sizing: border-box;
    background: #FFFFFF24;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    padding: 10px 14px 10px 14px;
    width: max-content;
    height: 50px;
    border-radius: 50px;

    font-family: 'InterRegular',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 100px;
}
.money-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.money-plus{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'InterBold',sans-serif;
    font-size: 34px;
    gap: 5px;
    color: #FFFFFF;
    margin-bottom: 100px;
}
.money-plus-rep{
    margin-top: 0;
    margin-bottom: 114px;
}
.done-button{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    gap: 4px;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    background: radial-gradient(50% 689.8% at 50% 45.3%, #000000 0%, #5F2DCA 100%);
    backdrop-filter: blur(40px);
    border-radius: 50px;

    font-family: 'SF Pro Text',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}
.big-green{
    font-family: 'InterBold',sans-serif;
    color: #03FF65;
}
.fz-14{
    font-size: 14px;
}
.fz-20 {
    font-size: 20px;
}
.fz-24{
    font-size: 24px;
}
.fz-34{
    font-size: 34px;
}
.money-rep{
    margin-bottom: 114px;
}
.money-container-rep{
    margin-top: -10px;
}
.done-rep-title{
    margin-bottom: 114px;
}
@keyframes coin-animation {
    0% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(7px, -170px) scale(0.3);
        opacity: 0;
    }
}

.coin {
    animation: none;
}


@media screen and (max-height: 750px ){
    .money{
        margin-bottom: 90px;
    }
}
@media screen and (max-height: 730px ){
    .done-coins-title {
        margin-top: 60px;
    }
    .money{
        margin-bottom: 80px;
    }
}
@media screen and (max-height: 730px ){
    .money{
        margin-bottom: 70px;
    }
}
@media screen and (max-height: 700px ){
    .done-coins-title {
        margin-bottom: 80px;
        margin-top: 60px;
    }
    .money{
        margin-bottom: 60px;
    }
}
@media screen and (max-height: 670px ){
    .done-coins-title {
        margin-bottom: 60px;
        margin-top: 60px;
    }
    .money{
        margin-bottom: 60px;
    }
}

@media screen and (max-height: 650px ){
    .done-coins-title {
        margin-bottom: 40px;
        margin-top: 70px;
    }
    .money{
        margin-bottom: 55px;
    }
}
@media screen and (max-height: 600px ){
    .done-coins-title {
        margin-bottom: 60px;
        margin-top: 40px;
    }
    .money{
        margin-bottom: 60px;
    }
    .money-plus{
        margin-bottom: 60px;
    }
}

.animated-reward-item{
    position: absolute;
}
.animated-reward-item > *{
    width: 100%;
    height: 100%;
}