
.bg-2{
    background: linear-gradient(180deg, #5F2DCA 0%, #000000 90.6%) !important;
}
.upgrade-screen {
    padding: 14px 0 24px 0;
    height: 100%;
    margin-bottom: 20px;
    
    margin: 0 auto;
}

.upgrade-header{
    margin-right: 16px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
}
.upgrade-title{
    font-family: 'InterBold',sans-serif;
    font-size: 28px;
    color: #FFFFFF;
    margin: 0;
}
.coin-count{
    display: flex;
    align-items: center;
    padding: 10px 14px;
    gap: 5px;
    box-sizing: border-box;
    height: 50px;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 50px;
}
.coin-count img{
    width: 30px;
    height: 30px;
}
.coin-count-text{
    font-family: 'InterMedium',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}
.upgrade-types{
    display: flex;
    align-items: center;
}
.standart,
.vip{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    box-sizing: border-box;
    width: 50%;
    height: 50px;
    background: rgba(255, 255, 255, 0.17);
    backdrop-filter: blur(40px);
    border-radius: 20px 20px 0px 0px;

    font-family: 'SF Pro Text Medium',sans-serif;
    font-size: 16px;
    color: #FFFFFF;
}
.type-desactive{
    background: rgba(255, 255, 255, 0.17);
    opacity: 0.5;
    backdrop-filter: blur(40px);
}
.standart-items-container{
    background: rgba(255, 255, 255, 0.17);
    
    
}
.standart-items,
.vip-items{
    border-radius: 0px 0px 20px 20px;
    padding: 20px 26px 115px 26px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 10px;
    align-items: stretch;
}
.vip-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 25px;
    gap: 10px;
    box-sizing: border-box;
    width: 165px;
    height: 226px;
    background: rgba(255, 255, 255, 0.14);
    backdrop-filter: blur(25px);
    border-radius: 16px;
}
.standart-item{
    width: calc(50% - 5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 0;
    gap: 10px;
    box-sizing: border-box;
    min-height: 170px;
    background: rgba(255, 255, 255, 0.14);
    backdrop-filter: blur(25px);
    border-radius: 16px;
}
.standart-item-cost{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    background: #000000;
    opacity: 0.5;
    backdrop-filter: blur(40px);
    box-sizing: border-box;
    width: 145px;
    height: 50px;
    border-radius: 50px;
    padding: 13px 21px;
}
.vip-item-cost{
    margin-top: 38px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #000000;
    opacity: 0.5;
    backdrop-filter: blur(40px);
    box-sizing: border-box;
    width: 145px;
    height: 50px;
    border-radius: 50px;
    padding: 13px 23px;

}
.star,
.rubin{
    display: flex;
    align-items: center;
    column-gap: 4px;
}
.rubin-text,
.star-text{
    font-family: 'SF Pro Text', sans-serif;
    font-size: 16px;
    color: #FFFFFF;
}
 .vip-item-cost{
    background: transparent;
    opacity: 1;
    backdrop-filter: none;
    padding: 0;
    margin: 15px 0;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    width: 100%;
}
.open-standart-item .standart-image {
    width: 40px;
    height: 40px;
    background-image: url('../../img/coin4.png');
    background-size: cover;
    background-position: center;
}
.standart-item-cost-timer{
    background-color: #000000;
    border-radius: 50px;
}
.vip-item-cost-timer{
    background-color: #000000;
    border-radius: 50px;
    margin-top: 34px;
}
.open-standart-item .coin-count-text{
    font-family: 'SF Pro Text Medium',sans-serif;
    font-size: 16px;
    color: #FFFFFF;
}
.open-standart-item .standart-item-cost{
    background: transparent;
    opacity: 1;
    backdrop-filter: none;
    padding: 0;
}
.standart-image {
    width: 40px;
    height: 40px;
    background-image: url('../../img/lock.svg');
    background-size: cover;
    background-position: center;
}

.standart-item-title{
    font-family: 'InterBold',sans-serif;
    font-size: 18px;
    color: #FFFFFF;
    max-width: 100%;
    text-align: center;
}
.coin-timer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    column-gap: 4px;
    box-sizing: border-box;
    width: 145px;
    height: 50px;
    background: #000000;
    backdrop-filter: blur(40px);
    border-radius: 50px;
}
.coin-time{
    font-family: 'SF Pro Text Medium',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}

.vip-items{
    display: none;
}
.bold{
    font-family: "InterBold",sans-serif;
}
.custom-select option {
    background-color: #1a1836; /* Цвет фона для опций */
    color: white;
    padding: 10px; /* Отступы внутри опций */
}


@media screen and (max-width: 389px){
    .standart-items, .vip-items {
        padding: 17px 18px 115px 18px;
    }
}
.standart-item-icon{
    position: relative;
}
.update-timer{
    position: absolute;
    background-color: #000000;
    border-radius: 50px;
    padding: 4px;
    font-size: 12px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 4px;
    align-items: center;
}