.friends-screen {
    padding: 14px 16px 24px 16px;
    height: 100%;
    margin-bottom: 20px;
    
    margin: 0 auto;
    padding-bottom: 234px;
}

.friends-title{
    font-family: 'InterBold',sans-serif;
    font-size: 28px;
    text-align: center;
}
.friends-text{
    font-family: 'InterRegular',sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 14px;
}
.total-referral{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'InterBold',sans-serif;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 14px;
}
.total-money {
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding: 10px 14px;
    /* width: 152px; */
    height: 50px;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 50px;
    max-width: 348px;

    font-family: 'InterBold',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}
.yourf{
    font-family: 'InterBold',sans-serif;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 8px;
}
.friend-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 10px;
    width: 100%;
    height: 68px;
    background: rgba(255, 255, 255, 0.14);
    backdrop-filter: blur(25px);
    border-radius: 16px;
}
.fried-info{
    display: flex;
    align-items: center;
    column-gap: 8px;

    font-family: 'InterBold',sans-serif;
    font-size: 16px;
    color: #FFFFFF;
}
.fried-info img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.plus-money{
    display: flex;
    align-items: center;
    column-gap: 4px;

    font-family: 'InterBold',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}
.friends-items{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-bottom: 250px;
}
.friends-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    box-sizing: border-box;
    width: 100%;
    max-width: 358px;
    left: calc(50% - 179px);
    height: 60px;
    background: radial-gradient(50% 689.8% at 50% 45.3%, #000000 0%, #5F2DCA 100%);
    backdrop-filter: blur(40px);
    border-radius: 50px;
    position: sticky;
    bottom: 107px;
}

.link{
    display: flex;
    position: sticky;
    bottom: 179px;
    justify-content: space-between;
    align-items: center;
    padding: 18px 16px;
    width: 100%;
    max-width: 358px;
    left: calc(50% - 179px);
    height: 60px;
    box-sizing: border-box;
    background: #000000;
    border: 1.5px solid rgba(255, 255, 255, 0.17);
    backdrop-filter: blur(40px);
    border-radius: 50px;
}










