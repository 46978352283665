.home-page-video-task {
  position: absolute;
  bottom: 90px;
  left: 0;
  width: 100%;
  padding: 18px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 150;
  border: none;
  outline: none;
  background: linear-gradient(270deg, #251b8b 0%, #e02864 50%, #251b8b 100%);
}
.home-page-video-task ~ .farming{
  bottom: 90px;
  background: none;
}
.home-page-video-task ~ .farming .farming-line,.home-page-video-task ~ .farming p{
  display: none;
}
.home-page-video-task * {
  font-family: "InterRegular", sans-serif;
  color: white;
}
.home-page-video-task > * {
  display: flex;
  align-items: center;
  gap: 5px;
}
.video-task-reward img {
  width: 30px;
  height: 30px;
}
.video-task-text {
  font-weight: 700;
  font-size: 14px;
  padding: 4px 8px;
  text-transform: uppercase;
  border-radius: 50px;
  border: 1px solid #f164af;
}
.video-task-type span {
  text-transform: uppercase;
}
