body{
    background: url('./img/bg-light-glow.png') no-repeat fixed center/cover;
    
    margin: 0 auto;
}
button{
    cursor: pointer;
}
.onboarding-lottie-animation{
    position: absolute;
    top: 0;
    left: 0;
}
.screen-1{
    padding: 0 15px 0 15px;
}
.screen-2,
.screen-3{
    position: relative;
    padding: 14px 26px 0 26px;
}
.screen-3{
    text-align: center;
    padding-top: 79.75px;
}
.screen-2{
    padding-top: 45px !important;
    height: 100%;
   
}
.screen-2 .check{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 65px;
}
.page-3 p:nth-child(1){
    max-width: 263px;
    font-family: 'InterRegular',sans-serif;
    font-size: 20px;
    margin: 0 auto 20px auto;
}
.page-3 p:nth-child(2){
    width: 339px;
    margin: 0 auto 20px auto;
    font-family: 'InterRegular',sans-serif;
    font-size: 20px;
}
.page-3 h2{
    margin: 0 auto;
    
    font-family: 'InterBold',sans-serif;
    font-size: 28px;
    color: #03FF65;
    margin-top: 80px;
    margin-bottom: 80px;
}
.map-image{
    margin-top: 14px;
    margin-bottom: 40px;
}
.map-image img{
    margin: 0 auto;
    display: block;
}
.status-bar{
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.14);
}
.line-screen-1{
    width: 50%;
    height: 4px;
    background-color: #9C59CC;
}
.screen-1 .left-arrow{
    visibility: hidden;
}
.lang-title,
.check-title{
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    line-height: 33px;
}
.check-title{
    max-width: 283px;
    margin: 0 auto;
    margin-bottom: 82px;
}
.lang-subtitle{
    text-align: center;
    font-size: 20px;
    margin-bottom: 40px;
}
.reward{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    margin-bottom: 40px;
}
.reward img{
    width: 100px;
    height: 100px;
}
.reward-count{
    font-weight: bold;
    font-size: 50px;
    color: #FFFFFF;
}
.right-arrow, .left-arrow{
    padding: 18px 16px;
    background: #000000;
    border: 1.5px solid #3A3A3A;
    backdrop-filter: blur(40px);
    border-radius: 50%;
    width: 60px;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.page-number-nav {
    width: 100%;
    max-width: 338px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 24px;
}
.screen-2 .page-number-nav{
    margin: 0;
}
.screen-3 .page-number-nav{
    margin: 0;
}
.page-number{
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: 5px;
    text-indent: 5px;
}
.lang-language{
    margin: 0 auto;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    padding: 18px 16px;
    width: 100%;
    max-width: 338px;
    box-sizing: border-box;
    border: 1.5px solid #3A3A3A;
    backdrop-filter: blur(25px);
    border-radius: 50px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none"><path d="M4 6.1001L0.535899 0.850097L7.4641 0.850098L4 6.1001Z" fill="white"/></svg>');
    background-repeat: no-repeat, repeat;
    background-position: right 16px top 50%, 0 0;
    background-size: .65em auto, 100%;

    font-family: 'SF Pro Text', sans-serif;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.7;
}


.line-screen-2{
    margin-left: 50%;
    width: 50%;
    height: 4px;
    background-color: #9C59CC;
}
.check-years{
    font-family: 'InterRegular',sans-serif;
    font-size: 34px;
    color: #03FF65;
    margin-bottom: 82px;
}
.big-num{
    font-family: 'InterBold',sans-serif;
    font-size: 70px;
    color: #03FF65;
}
.check{
    text-align: center;
}
.pre-reward{
    font-family: 'InterBold',sans-serif;
    font-size: 28px;
    color: #FFFFFF;
    margin-bottom: 10px;
    margin-top: 82px;
}
.rep-count{
    font-size: 50px;
}


/* .line-screen-3{
    margin-left: 66%;
    width: 33%;
    height: 4px;
    background-color: #9C59CC;
} */




.reward-screen{
    padding-right: 18px;
    padding-left: 18px;
    height: 100vh !important;
}
.reward-day{
    font-family: 'InterBold',sans-serif;
    font-size: 50px;
    margin-top: 50px;
    margin-bottom: 74px;
}
.reward-text{
    font-family: 'InterRegular',sans-serif;
    font-size: 28px;
}
.reward-text-2{
    font-family: 'InterRegular',sans-serif;
    font-size: 20px;
}
.reward-button{
    position: fixed;
    bottom: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    max-width: 354px;
    width: 100%;
    left: calc(50% - 177px);
    height: 60px;
    background: #000000;
    border: 1.5px solid #3A3A3A;
    backdrop-filter: blur(40px);
    border-radius: 50px;

    font-family: 'SF Pro Text Medium',sans-serif;
    font-size: 20px;



}
.reward-screen{
    text-align: center;
}
.reward-screen .reward{
    margin-top: 64px;
    margin-bottom: 60px;
}


.custom-select-wrapper{
    position: relative;
    display: block;
    user-select: none;
    margin: 0 auto;
    text-align: center;
}
.custom-select{
    z-index: 1;
    position: relative;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: 18px 16px;
    isolation: isolate;
    width: 100%;
    max-width: 338px;
    height: 60px;
    background: #000000;
    border: 1.5px solid #3A3A3A;
    backdrop-filter: blur(25px);
    border-radius: 50px;
    margin: 0 auto;
}
.custom-select-trigger{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}
.custom-options,
.custom-select-trigger{
    font-family: "SF Pro Text Regular",sans-serif;
    font-size: 15px;
    color: rgba(255, 255, 255, 1);
}
.custom-option{
    display: block;
    margin-right: 6px;
}
.custom-options .custom-option:hover {
    color: #9C59CC;
}
.custom-select.open .custom-options {
    display: flex;
    overflow: hidden;
}
.custom-select.open{
    border-radius: 20px 20px 0 0;
    border-bottom: none;
}
.custom-select.open .arrow-top{
    display: block;
}
.arrow-top{
    display: none;
}
.custom-select.open .arrow-down{
    display: none;
}
.custom-options {
    display: none;
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: -1.5px;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    width: calc(100% + 3px);
    height: 108px;
    background: #000;
    backdrop-filter: blur(40px);
    border-radius: 0px 0px 20px 20px;
    border: 1.5px solid #3A3A3A;
    row-gap: 24px;
    border-top: none;
    overflow: scroll;
}
.page-number-nav {
    /* bottom: 58px; */
}

.screens-container{
    width: 100%;
    height: 100%;
}
.screens-track{
    height: 100%;
    width: 200%;
    display: flex;
}
.screens-track > *{
    width: 50%;
}

@media screen and (max-height: 700px) {
    .reward-day {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .reward-screen .reward {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .map-image img {
        width: 315px;
        height: 150px;
    }
    .map-image{
        text-align: center;
    }
    .screen-3 {
        padding-top: 60px;
    }
}
@media screen and (max-height: 630px) {
    .reward-day {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .reward-screen .reward {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .page-3 h2 {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .lang-title{
        font-size: 26px;
    }
    .lang-subtitle {
        font-size: 18px;
    }
    .reward-count {
        font-size: 40px;
    }
    .reward img {
        width: 90px;
        height: 90px;
    }
    .map-image{
        margin-bottom: 30px;
    }
    .lang-subtitle {
        margin-bottom: 30px;
    }
    .reward {
        margin-bottom: 30px;
    }
    .custom-options{
        height: 85px;
        font-size: 18px;
    }
    .custom-select{
        height: 55px;
    }
}
@media screen and (max-height: 600px){
    .reward {
        margin-bottom: 25px;
    }
    .reward-count {
        font-size: 35px;
    }
    .reward img {
        width: 80px;
        height: 80px;
    }
}

@media screen and (max-width: 390px){
    .page-3 p:nth-child(1) {
        font-size: 18px;
    }
    .page-3 p:nth-child(2) {
        font-size: 18px;
        width: 305px;
    }
    .page-3 h2 {
        font-size: 26px;
    }
    .reward-count {
        font-weight: bold;
        font-size: 48px;
        color: #FFFFFF;
    }
    .reward img {
        width: 98px;
        height: 98px;
    }
}

@media  screen and (max-width: 389px) {
    .page-3 p:nth-child(1) {
        max-width: 250px;
    }

}

@media  screen and (max-width: 380px){
    .map-image img {
        width: 350px;
        height: 190px;
    }
    .map-image{
        text-align: center;
    }
}
@media  screen and (max-width: 375px) {
    .reward-button {
        max-width: 330px;
        left: calc(50% - 165px);
    }

}
@media  screen and (max-width: 370px) {
    .page-number-nav {
        width: 333px;
    }
}
@media  screen and (max-width: 351px) {
    .page-3 p:nth-child(1) {
        max-width: 234px;
    }

}
@media  screen and (max-width: 350px) {
    .page-3 p:nth-child(1) {
        font-size: 17px;
    }
    .page-3 p:nth-child(2) {
        font-size: 17px;
        width: 288px;
    }

}
