
.container{
    height: 100vh;
    position: relative;
    
    margin: 0 auto;
}
.loading-bar {
    height: 41px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 48px;
}
.progress {
    height: 3px;
    background-color: #9C59CC;
    width: 0;
    border-radius: 10px;
    transition: .1s;
}
.coin {
    transition: transform 5s;
}
.coin-container img{
    width: 97px;
    height: 95px;
    border-radius: 50%;
}
.coin-container{
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 41px);
    top: calc(50% - 82px);
    transform: translate( calc(-50% + 41px) , calc(-50% + 61.5px));
    display: flex;
    align-items: center;
    justify-content: center;
}
.coin-background {
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #6633D4 0%, #0039A6 100%);
    filter: blur(30px);
    z-index: -1;
    transition: width 5s, height 5s, background-size 5s;
}

.percentage{
    font-family: 'InterBold',sans-serif;
    font-size: 20px;
    line-height: 120%;
    color: #9C59CC;
    margin-bottom: 14px;
    text-align: center;
}


.flash-text{
    animation: flashText 1s linear infinite;
}
@keyframes flashText {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}